/**
 * Stores build information about <b>this specific version of the `ruffle-core` library</b>.
 *
 * It does not represent the version of Ruffle that may be in use by the page (for example, if a browser extension overrides it).
 *
 * @privateRemarks
 * This is generated at build time via `set_version.ts`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2024-11-20",
    versionChannel: "nightly",
    buildDate: "2024-11-20T00:06:14.118Z",
    commitHash: "fb8d950a455c65245bd40c2e96427c16c3a9e26e",
};
